<template>
    <section id="first-section">
        <div id="text-container">
            <h1 id="name">Over Under Reviews </h1>
            <!-- <h2 id="fairmount-park">Fairmount Park | Philadelphia, PA</h2> -->
        </div>
        <!-- <div class="overlay"></div> -->
    </section>
    <section id="second-section" class="container">
            <div id="second-section-container">
                <h1 class="section-header-text">About </h1>
                <div id="profile-image-container">
                    <img id="profile-image" src="../assets/reshot-icon-popcorn.svg">
                </div>
                <div id="about-me-container">
                    <p class="paragraph-text"> Welcome! to my movie website <br> <br> I noticed my opinions 
                        and experiences with films are sometimes different, and at times dramatically so, from the general consensus of professional movie critics 
                        on rotten tomatoes. I decided to create my own website to share my thoughts with friends and other movie enthusiasts who are interested in what I'm currently watching
                        and rate the critics’ consensus on those movies by using a simple rating system: overrated <img class="about-section-icons" src="../assets/reshot-icon-dislike.svg">, 
                        underrated <img class="about-section-icons" src="../assets/reshot-icon-like.svg">, or just right <img class="about-section-icons" src="../assets/reshot-icon-check.svg">, 
                        denoting if I thought the consensus was too high, too low, or if they were spot on. <br> <br> For those who appreciate my taste in movies, 
                        I also give recommendations for films both new and old I really enjoyed, with a steady stream of horror movies paired with a film from another genre. 
                        In addition, every October I curate a Halloween watchlist for the month and share it here for those who want to 
                        join in on the Halloween spirit. 
                        <!-- <br><br> -->
                        
                        <!-- <button class="halloween-movie-info-button">
                            <a href="#special-halloween-section-content" 
                            class="movie-info-link" id="halloween-movie-info-link"> Halloween Watchlist </a>
                        </button> -->
                        
                        <br> <br> Thanks for visiting my movie website. 
                        If you want to say hey, buy me a beer <img id="beer-mug" src="../assets/reshot-icon-beer-mug.svg"> for a great movie recommendation, 
                        and/or talk about any of the films listed here visit my <a href="#contact-section-content">Contact</a> section—I'm always up for a good movie discussion!
                    </p>
                </div>
            </div>
    </section>
        <section id="fourth-section" class="container">
           <div>
               <h1 id="movie-recommendations-text" class="section-header-text">Featured Recommendations </h1>
           </div>
            <div class="movie-image-container" id="featured-movie-recommendations">
                <div id="first-movie-container">
                    <h2 class="genre-heading">Horror / Mystery / Drama</h2>
                    <img class="movie-images" src="../assets/The-Cured.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2018</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${theCured}`" 
                                target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info 
                            </a>  
                        </button>
                    </h3>
                    <!-- <p class="movie-text"> With an American remake recently released, I thought this would be a great time to revisit the original German film. 
                        This film is centered on a mother and her twin boys whom all seem to have a great loving relationship; however, much of that relationship—between 
                        the twin boys and their mother—is brought into question when she comes home to heal from a medical procedure. This is where the story really 
                        begins to hit its dark ominous stride. This movie really shines is in its unsettling slow burn premise that builds throughout its runtime 
                        and makes you question as a viewer “is she or isn’t she.” What I also liked about this movie is it makes you wonder who is the antagonist 
                        and protagonist because the lines become blurry as the story progresses. Simply put, I thought the mystery on display is sublime.

                    </p> -->
                </div>
                <div id="second-movie-container">
                    <h2 class="genre-heading">Sci-Fi / Mystery &amp; Thriller  </h2>
                    <img class="movie-images" src="../assets/Ex-Machina.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2015</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${exMachina}`" 
                               target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info 
                            </a>
                        </button>
                    </h3>
                    <!-- <p class="movie-text"> Even though this film has favorable ratings on rotten tomatoes and did a solid job at the box office, I still feel it was 
                        undervalued for how well of a movie I thought this was. It touched on topics that are still issues today and have been for a long time for 
                        some communities. The social issues are addressed head on in this crime drama through its dialogue and the powerful moments they share together 
                        and with other characters along the way. One of the things I liked most about this movie was how the dialogue between the two main characters 
                        were peppered with anecdotes about their values, their desires, or simply things they liked or disliked. These anecdotes would pop up later 
                        in the movie adding to the tender moments they shared amongst the chaos surrounding them. Looking past the social issues on display in this 
                        film, I thought this was one of the best love stories in the last decade brought to life with great on screen chemistry portrayed by 
                        Daniel Kaluuya and Jodie Turner-Smith.
                    </p> -->
                </div>
            </div>
            <div class="movie-button-container">
                <a href="#additional-movie-recommendations">
                    <button class="show-movies-button" id="more-recommendations-button" @click="showMoreRecommendations = !showMoreRecommendations" 
                        v-show="!showMoreRecommendations"> More Recommendations
                    </button>
                </a>
            </div>
            <div class="movie-button-container">
                <a href="#featured-movie-recommendations">
                    <button class="show-movies-button" id="more-recommendations-button" @click="showMoreRecommendations = !showMoreRecommendations" 
                        v-show="showMoreRecommendations"> Less Recommendations
                    </button>
                </a>
            </div>
            <div class="movie-image-container" id="additional-movie-recommendations" v-show="showMoreRecommendations">
                <div class="additional-movies">
                    <h2 class="genre-heading">Horror / Mystery &amp; Thriller</h2>
                    <img class="movie-images" src="../assets/Goodnight-Mommy.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2014</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">                        
                            <a :href="'https://www.rottentomatoes.com/m/' + `${goodnightMommy}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Crime / Drama</h2>
                    <img class="movie-images" src="../assets/Queen-and-Slim.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2019</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${queenAndSlim}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Action / Comedy</h2>
                    <img class="movie-images" src="../assets/Attack-The-Block.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2011</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${attackTheBlock}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>    
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Horror / Mystery &amp; Thriller</h2>
                    <img class="movie-images" src="../assets/The-Monster.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2016</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${theMonster}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Biography / Drama</h2>
                    <img class="movie-images" src="../assets/King-Richard.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2021</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                       <button class="movie-info-button-recommendations">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${kingRichard}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button> 
                    </h3>
                </div> 
                <div class="additional-movies">
                    <h2 class="genre-heading">Comedy / Drama</h2>
                    <img class="movie-images" src="../assets/Mid90s.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2018</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${mid90s}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Fantasy / Mystery &amp; Thriller</h2>
                    <img class="movie-images" src="../assets/The-Vast-Of-Night.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2019</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${theVastOfNight}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Crime / Drama</h2>
                    <img class="movie-images" src="../assets/Blood-On-Her-Name.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2019</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">                        
                            <a :href="'https://www.rottentomatoes.com/m/' + `${bloodOnHerName}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>         
                        </button>    
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Mystery &amp; Thriller</h2>
                    <img class="movie-images" src="../assets/Rent-A-Pal.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2020</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">                        
                            <a :href="'https://www.rottentomatoes.com/m/' + `${rentAPal}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Mystery &amp; Thriller</h2>
                    <img class="movie-images" src="../assets/The-Call.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2020</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">                        
                            <a :href="'https://www.rottentomatoes.com/m/' + `${theCall}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Horror / Comedy</h2>
                    <img class="movie-images" src="../assets/Re-Animator.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 1985</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">                        
                            <a :href="'https://www.rottentomatoes.com/m/' + `${reAnimator}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>    
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Western / Adventure</h2>
                    <img class="movie-images" src="../assets/News-Of-The-World.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2020</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">                        
                            <a :href="'https://www.rottentomatoes.com/m/' + `${newsOfTheWorld}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Horror / Mystery &amp; Thriller</h2>
                    <img class="movie-images" src="../assets/The-Girl-With-All-The-Gifts.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2016</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">                        
                            <a :href="'https://www.rottentomatoes.com/m/' + `${theGirlWithAllTheGifts}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Horror / Drama</h2>
                    <img class="movie-images" src="../assets/Raw.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2016</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${raw}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Horror</h2>
                    <img class="movie-images" src="../assets/Anything-For-Jackson.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2020</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${anythingForJackson}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Sci-fi / Mystery &amp; Thriller</h2>
                    <img class="movie-images" src="../assets/Arrival.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2016</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${arrival}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Sci-fi / Horror</h2>
                    <img class="movie-images" src="../assets/Sputnik.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2020</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${sputnik}`" 
                                target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info 
                            </a>  
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Drama / Mystery &amp; Thriller </h2>
                    <img class="movie-images" src="../assets/Pig.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2021</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${pig}`" 
                               target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info 
                            </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Horror / Mystery &amp; Thriller </h2>
                    <img class="movie-images" src="../assets/The-Autopsy-Of-Jane-Doe.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2016</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${theAutopsyOfJaneDoe}`" 
                               target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info 
                            </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Comedy / Fantasy / Sci-Fi </h2>
                    <img class="movie-images" src="../assets/Sorry-To-Bother-You.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2018</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${sorryToBotherYou}`" 
                               target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info 
                            </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Horror / Mystery &amp; Thriller</h2>
                    <img class="movie-images" src="../assets/High-Tension.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2005</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">                        
                            <a :href="'https://www.rottentomatoes.com/m/' + `${highTension}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Mystery &amp; Thriller</h2>
                    <img class="movie-images" src="../assets/Panic-Room.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2022</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">                        
                            <a :href="'https://www.rottentomatoes.com/m/' + `${panicRoom}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Horror / Mystery &amp; Thriller</h2>
                    <img class="movie-images" src="../assets/Scary-Stories-To-Tell-In-The-Dark.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2019</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">                        
                            <a :href="'https://www.rottentomatoes.com/m/' + `${scaryStoriesToTellInTheDark}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="additional-movies">
                    <h2 class="genre-heading">Comedy</h2>
                    <img class="movie-images" src="../assets/Bridesmaids.jpg">
                    <h2 id="movie-images-release-date" class="genre-heading release-date">Release Date: 2011</h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="movie-info-button-recommendations">                        
                            <a :href="'https://www.rottentomatoes.com/m/' + `${bridesmaids}`" 
                            target="_blank" class="movie-info-link" id="movie-recommendation-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
            </div>
            <div class="movie-button-container">
                <a href="#featured-movie-recommendations">
                    <button class="show-movies-button" id="more-recommendations-button" @click="showMoreRecommendations = !showMoreRecommendations" 
                        v-show="showMoreRecommendations"> Less Recommendations
                    </button>
                </a>
            </div>
        </section>
        <section id="movie-reviews-container">
            <div>
                <h1 class="movie-reviews-header-text">Movie Reviews</h1>
            </div>
            <div class="movie-reviews-image-container" id="current-year-reviews" v-show="!showReviews">
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Action / Adventure / Fantasy </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Captain-America-Brave-New-World.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: February 2025 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${captainAmericaBraveNewWorld}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Anthony Mackie capably takes up Cap's mantle and shield, but Brave New World is too routine and overstuffed
                                with uninteresting easter eggs to feel like a worthy standalone adventure for this new Avengers leader"
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-like.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                    <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Mystery &amp; Thriller / Romance </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/The-Gorge.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: February 2025 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${theGorge}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Mixing multiple genres, The Gorge makes for a surprisingly endearing romance until its action-thriller 
                                obligations steer proceedings back onto a more predictable path."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
            </div>
            <div class="movie-button-container">
                <a href="#previous-year-reviews">
                    <button class="show-movies-button" @click="showReviews = !showReviews" v-show="!showReviews"> 2024 Movie Reviews</button>
                </a>
            </div>
            <div class="movie-button-container">
                <a href="#current-year-reviews">
                    <button class="show-movies-button" @click="showReviews = !showReviews" v-show="showReviews"> 2025 Movie Reviews</button>
                </a>
            </div>
            <div class="movie-reviews-image-container" id="previous-year-reviews" v-show="showReviews">
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Sci-Fi / Action / Adventure </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Godzilla-x-Kong.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: March 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${godzillaXKong}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Come to Godzilla x Kong: The New Empire for the sheer monster-mashing spectacle -- and stay for that too, 
                                because the movie doesn't have much else to offer.."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Horror / Mystery &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Late-Night-With-The-Devil.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: March 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${lateNightWithTheDevil}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Delightfully dark, Late Night with the Devil proves possession horror isn't played out -- and serves as an outstanding 
                                showcase for David Dastmalchian."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Horror </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Immaculate.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: March 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${immaculate}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Immaculate in conception if not always in execution, this religiously themed horror outing is saved by a 
                                divine performance from Sydney Sweeney."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-dislike.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Action / Mystery &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Road-House.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: March 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${roadHouse}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Road House updates a cult classic for a new generation, happily replicating the original's emphasis 
                                on cheesy brawn over narrative brains."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Sci-Fi / Adventure / Action </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Dune-Part-Two.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: March 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${dunePartTwo}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Visually thrilling and narratively epic, Dune: Part Two continues Denis Villeneuve's adaptation of 
                                the beloved sci-fi series in spectacular form."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Horror / Mystery &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Exhuma.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: March 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${exhuma}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                No Critics Consensus at this time
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-like.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Horror / Mystery &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Abigail.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: April 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${abigail}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Carrying off well-worn vampire tropes with a balletic flourish, Abigail dances around the familiarity 
                                of its premise with a game cast and slick style."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Horror / Mystery &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/The-First-Omen.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: April 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${theFirstOmen}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Frequently frightening even as it plays within the confines of a nearly 50-year-old film series, 
                                this prequel is The First Omen of a bright future for the franchise in quite some time."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Drama / Action &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Civil-War.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: April 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${civilWar}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Tough and unsettling by design, Civil War is a gripping close-up look at the violent uncertainty 
                                of life in a nation in crisis."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Action / Mystery &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Monkey-Man.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: April 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${monkeyMan}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "An audacious effort from debuting director Dev Patel, Monkey Man dispenses action 
                                and sociopolitical commentary with equal aplomb."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Sci-Fi / Drama / Mystery </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/I-Saw-The-Tv-Glow.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: May 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${iSawTheTvGlow}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "With a distinctive visual aesthetic that enhances its emotionally resonant narrative, 
                                I Saw the TV Glow further establishes writer-director Jane Schoenbrun as a rising talent."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-like.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Action / Adventure / Sci-Fi </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Furiosa-A-Mad-Max-Saga.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: May 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${furiosaAMadMaxSaga}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Retroactively enriching Fury Road with greater emotional heft if not quite matching it in propulsive throttle, Furiosa 
                                is another glorious swerve in mastermind George Miller's breathless race towards cinematic Valhalla."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Horror / Mystery &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/New-Life.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: May 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${newLife}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Breathing New Life to the genre, this excellently acted brooding horror thriller marks a propitious debut for John Rosman."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-dislike.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Horror / Sci-Fi / Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/A-Quiet-Place-Day-One.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: June 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${aQuietPlaceDayOne}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Grounded in raw humanity by Lupita Nyong'o and Joseph Quinn, this sideways entry into A Quiet Place finds fresh notes of 
                                fright to play amid the silence."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-dislike.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Action / Comedy </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Bad-Boys-Ride-Or-Die.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: June 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${badBoysRideOrDie}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Will Smith and Martin Lawrence remain good company even when Bad Boys strains to 
                                up the ante, proving there's still life left in this high-octane franchise."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-like.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Horror / Mystery &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Longlegs.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: July 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${longlegs}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Saturated in disquieting mood while leveraging a nightmarishly gonzo performance by Nicolas Cage, Longlegs 
                                is a satanic horror that effectively instills panic."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-like.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Horror / Mystery &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Maxxxine.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: July 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${maxxxine}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Intoxicating with its maxxximal style, MaXXXine is an uneven but vibrant pastiche that drives a 
                                stiletto through Hollywood's heart."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Action / Adventure &amp; Comedy </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Deadpool-And-Wolverine.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: July 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${deadpoolAndWolverine}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Ryan Reynolds makes himself at home in the MCU with acerbic wit while Hugh Jackman provides an 
                                Adamantium backbone to proceedings in Deadpool &amp; Wolverine, an irreverent romp with 
                                a surprising soft spot for a bygone era of superhero movies."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-dislike.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Horror / Mystery &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Oddity.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: July 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${oddity}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "An elegant and spooky ghost story punctuated with clever jolts, Oddity hews 
                                to the fundamentals of fright and achieves shout-inducing results."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Sci-Fi / Horror </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Alien-Romulus.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: August 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${alienRomulus}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Honoring its nightmarish predecessors while chestbursting at the seams with new frights of its own, 
                                Romulus injects some fresh acid blood into one of cinema's great horror franchises."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Horror / Mystery &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Cuckoo.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: August 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${cuckoo}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Directed with inventive flair by Tilman Singer while given flavorful personality by stars Hunter Schafer and 
                                Dan Stevens, Cuckoo is a madcap madhouse horror that's on the right side of deranged."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Mystery &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Trap.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: August 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${trap}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "An arch thriller given some grounding by Josh Hartnett's committed performance, Shyamalan's Trap will 
                                ensnare those who appreciate its tongue-in-cheek style while the rest will be eager to wriggle out from it."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Horror / Mystery &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/The-Deliverance.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: August 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${theDeliverance}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Lee Daniels' foray into horror works best when it leans into his melodramatic 
                                preoccupations, but it fails to deliver the exorcism goods."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Horror / Drama </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/The-Substance.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: August 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${theSubstance}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Audaciously gross, wickedly clever, and possibly Demi Moore's finest hour, 
                                The Substance is a gasp-inducing feat from writer-director Coralie Fargeat."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-check.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Horror / Mystery &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Terrifier-3.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: October 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${terrifier3}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Taking an axe to yuletide cheer and everything else in its path, Terrifier 3 is a bloody fantastic stocking 
                                stuffer for the holiday season."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-dislike.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Horror / Mystery &amp; Thriller </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Smile-2.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: October 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${smile2}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Blessed with a nerve-jangling star turn by Naomi Scott, writer-director Parker Finn 
                                broadens Smile's conceit into a pop stardom nightmare that'll leave a rictus grin on horror fans' faces."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-dislike.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
                <div class="movie-reviews-poster-container">
                    <h2 class="genre-heading movie-reviews-genre-heading"> Drama / Adventure &amp; Action </h2>
                    <img class="movie-images movie-reviews-movie-image" src="../assets/Gladiator-2.jpg">
                    <h2 class="genre-heading movie-reviews-release-date">Release Date: November 2024 </h2>
                    <div class="ratings-container">
                        <h3 class="movie-info-paragraph-wrapper">
                            <button class="movie-info-button-reviews">
                                <a :href="'https://www.rottentomatoes.com/m/' + `${gladiator2}`" 
                                target="_blank" class="movie-info-link" id="movie-review-info-link"> Movie Info </a>
                            </button>
                            </h3>
                            <p class="critics-consensus-heading"> Critics' Consensus:</p>
                            <p class="critics-consensus-text"> 
                                "Echoing its predecessor while upping the bloodsport and camp, Gladiator II is an action 
                                extravaganza that derives much of its strength and honor from Denzel Washington's scene-stealing performance."
                            </p>
                            <p class="my-rating"> My rating: </p> <img class="my-rating-icon" src="../assets/reshot-icon-like.svg">
                            <p class="my-rating-text"></p>
                    </div> 
                </div>
            </div>
            <div class="movie-button-container">
                <a href="#current-year-reviews">
                    <button class="show-movies-button" @click="showReviews = !showReviews" v-show="showReviews"> 2025 Movie Reviews</button>
                </a>
            </div>
        </section>
        <!-- <section id="special-halloween-section" class="container">
            <div id="special-halloween-section-content">
                <h1 id="watchlist-header" class="special-halloween-header-text">2024 Halloween Watchlist</h1>
            </div>
            <div class="special-halloween-movie-image-container">
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/Cuckoo.jpg">
                    <h2 class="genre-heading release-date">Release Date: 2024 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${cuckoo}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/Dark-Water.jpg">
                    <h2 class="genre-heading release-date">Release Date: 2002 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${darkWater}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/Exhuma.jpg">
                    <h2 class="genre-heading release-date">Release Date: 2024 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${exhuma}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/Freaky.jpg">
                    <h2 class="genre-heading release-date">Release Date: 2019 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${freaky}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button> 
                    </h3>
                </div>
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/Infested.jpg">
                    <h2 class="genre-heading release-date">Release Date: 2023 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${infested}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/Maxxxine.jpg">
                    <h2 class="genre-heading release-date">Release Date: 2024 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${maxxxine}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/New-Life.jpg">
                    <h2 class="genre-heading release-date">Release Date: 2024 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${newLife}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/One-Cut-Of-The-Dead.jpg">
                    <h2 class="genre-heading release-date">Release Date: 2019 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${oneCutOfTheDead}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/Scare-Package.jpg">
                    <h2 class="genre-heading release-date">Release Date: 2019 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${scarePackage}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/Spontaneous.jpg">
                    <h2 class="genre-heading release-date">Release Date: 2020 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${spontaneous}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/Suitable-Flesh.jpg">
                    <h2 class="genre-heading release-date">Release Date: 2023 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${suitableFlesh}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/Terrifier-3.jpg">
                    <h2 class="genre-heading release-date">Release Date: 2024 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${terrifier3}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/The-Angry-Black-Girl-And-Her-Monster.jpg">
                    <h2 class="genre-heading release-date">Release Date: 2023 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${theAngryBlackGirlAndHerMonster}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/Them.jpg">
                    <h2 class="genre-heading release-date">Release Date: 2006 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${them}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/They-Live.jpg">
                    <h2 class="genre-heading release-date">Release Date: 1988 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${theyLive}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
                <div class="halloween-movie-poster-container">
                    <img class="movie-images special-halloween-movie-image" src="../assets/You-Wont-Be-Alone.jpg">
                    <h2 class="genre-heading release-date">Release Date: 2022 </h2>
                    <h3 class="movie-info-paragraph-wrapper">
                        <button class="halloween-movie-info-button">
                            <a :href="'https://www.rottentomatoes.com/m/' + `${youWontBeAlone}`" 
                            target="_blank" class="movie-info-link" id="halloween-movie-info-link"> Movie Info </a>
                        </button>
                    </h3>
                </div>
            </div>
        </section> -->
        <section id="contact-section" class="container">
            <h1 id="contact-section-header-text" class="section-header-text">Contact</h1>
            <div id="contact-section-content">
                <!-- <h1 id="contact-section-header-text" class="section-header-text">Contact</h1> -->
                <img class="contact-section-icons" id="mail-icon" v-on:click="email" src="../assets/reshot-icon-email-2.svg" alt="">
                <img class="contact-section-icons" id="venmo-icon" v-on:click="venmo" src="../assets/venmo-qr-code.png" alt="">
            </div>
        </section>
</template>

<script>
    export default {
        name: 'Home',
        data() {
            return {
                abigail: "abigail_2024",
                anythingForJackson: "anything_for_jackson",
                alienRomulus: "alien_romulus",
                aQuietPlaceDayOne: "a_quiet_place_day_one",
                attackTheBlock: "attack_the_block",
                arrival:"arrival_2016",
                badBoysRideOrDie: "bad_boys_ride_or_die",
                badTimesAtTheElRoyale: "bad_times_at_the_el_royale",
                bloodOnHerName: "blood_on_her_name",
                bridesmaids: "bridesmaids_2011",
                captainAmericaBraveNewWorld: "captain_america_brave_new_world",
                civilWar: "civil_war_2024",
                cuckoo: "cuckoo_2024",
                darkWater: "10002114-dark_water",
                deadpoolAndWolverine: "deadpool_and_wolverine",
                drive: "drive_2011",
                dunePartTwo: "dune_part_two",
                exhuma: "exhuma",
                exMachina: "ex_machina",
                fearStreetPartOne1994: "fear_street_part_one_1994",
                freaky: "freaky",
                furiosaAMadMaxSaga: "furiosa_a_mad_max_saga",
                gladiator2: "gladiator_ii",
                goodnightMommy: "goodnight_mommy",
                godzillaXKong: "godzilla_x_kong_the_new_empire",
                highTension: "high-tension-switchblade-romance",
                immaculate: "immaculate_2024",
                infested: "infested_2023",
                iSawTheTvGlow: "i_saw_the_tv_glow",
                knockAtTheCabin: "knock_at_the_cabin",
                kingRichard: "king_richard",
                lateNightWithTheDevil: "late_night_with_the_devil",
                longlegs: "longlegs",
                panicRoom: "panic_room",
                queenAndSlim: "queen_and_slim",
                theMonster: "the_monster_2016",
                theGirlWithAllTheGifts: "the_girl_with_all_the_gifts",
                theGorge: "the_gorge",
                maxxxine: "maxxxine",
                monkeyMan: "monkey_man",
                mid90s: "mid90s",
                oddity: "oddity",
                newLife: "new_life_2023",
                newsOfTheWorld: "news_of_the_world",
                nope: "nope",
                oneCutOfTheDead: "one_cut_of_the_dead",
                pig: "pig_2021",
                raw: "raw_2017",
                reAnimator: "reanimator",
                rentAPal: "rent_a_pal",
                roadHouse: "road_house",
                scarePackage: "scare_package",
                scaryStoriesToTellInTheDark: "scary_stories_to_tell_in_the_dark",
                sleepawayCamp: "sleepaway_camp",
                smile2: "smile_2",
                sorryToBotherYou: "sorry_to_bother_you_2018",
                spontaneous:"spontaneous",
                sputnik: "sputnik_2020",
                suitableFlesh: "suitable_flesh",
                terrifier3: "terrifier_3",
                theAngryBlackGirlAndHerMonster: "the_angry_black_girl_and_her_monster",
                theAutopsyOfJaneDoe: "the_autopsy_of_jane_doe",
                theCall: "the_call_2020_kr",
                theCured: "the_cured",
                theDeliverance: "the_deliverance", 
                theFirstOmen: "the_first_omen",
                them: "them_2007",
                theSubstance: "the_substance",
                theVastOfNight: "the_vast_of_night",
                theyLive: "they_live",
                trap: "trap_2024",
                youWontBeAlone: "you_wont_be_alone",
                showReviews: false,
                showMoreRecommendations: false
            }
        },
        methods: {
            email: () => {
                window.location.href = "mailto:contact@overunderreviews.com";
            },
            venmo: () => {
                 window.open("https://venmo.com/code?user_id=1326837829468160596&created=1625411784", "_blank");
            }
        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;1,400&display=swap');

    @import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@500&display=swap');
    

    #home {
        font-family: Avenir, Helvetica, Arial, sans-serif;
    }

    .section-header-text {
        font-family: 'Dancing Script';
    }

    a {
        text-decoration: none;
    }

    /* .container {
        height: 100%;
        max-width: 100%;
        padding: 40px 300px;
        margin-right: auto;
        margin-left: auto;
    } */
    
    /* ---------- BACKGROUND PICTURE SECTION ------------- */

    #name {
        font-size: 40px;
        font-family: 'Dancing Script';
        margin-bottom: 0;
    }

    #fairmount-park {
        font-family: 'Montserrat';
        margin-top: 0;
        font-size: 14px;
        margin-top: 2px;
    }

    #text-container {
        display: inline-block;
        position: relative;
        color: white;
        top: 10px;
        left: 35px;
        z-index: 4;
    }

    #first-section-header {
      display: inline-block;
      color: white;
      font-size: 60px;
    }

    #first-section {
        height: 1000px;
        background-image: url("../../src/assets/projector.jpg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    /* .overlay {
        height: 1000px;
        background-color: #66FCF1;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        opacity: 0.30;
        z-index: 2;
    } */


    /* ------------ ABOUT SECTION -------------- */

    #second-section-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    #second-section {
        background: #F3EFE0;
        /* background: #1F2833; */
        height: auto;
        padding-bottom: 40px;
        padding-top: 40px;
    }

    #profile-image 
    {
        height: 250px;
        width: 250px;
        border-radius: 50%;
        border: 7px solid #48b0a8;
    }

    .section-header-text {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 54px;
        color: #48b0a8;
        /* color: #66FCF1; */
    }

    #row-container {
        height: fit-content;
        display: flex;
        justify-content: space-evenly;
    }

    .section-paragraph {
        color: white;
        margin-bottom: 40px;
        font-size: 20px;
    }

    #about-me-container {
        text-align: center;
        margin-top: 20px;
        width: 42%;
    }

     .paragraph-text {
        /* color: white; */
        font-size: 18px;
        line-height: 26px;
        margin: 0;
        font-family: 'Montserrat Alternates';
    }

    .about-section-icons {
        height: 25px;
        width: 25px;
        vertical-align: middle;
    }

     /* #about-me-container-paragraph-text {
        color: black;
        font-family: 'Montserrat Alternates';
    } */

    /* #about-me-text {
        color: #48b0a8;
    } */

    #second-section-paragraph {
        color: black;
        font-family: 'Montserrat Alternates';
    }

    #beer-mug {
        height: 25px;
        width: 25px;
        vertical-align: middle;
    }

    /* #profile-image-container {
        text-align: center;
    }  */

    
    /* ----------- MOVIE RECOMMENDATION SECTION ------------ */

    #fourth-section {
        background: #1F2833;
        height: auto;
        padding-bottom: 40px;
        padding-top: 40px;
    }

    #movie-recommendations-text {
        margin-top: 0;
        text-align: center;
        padding-top: 10px;
        color: #66FCF1;
    }


    .movie-image-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 35px;
        flex-wrap: wrap;
    }

    .movie-images {
        width: 350px;
        height: 500px;
        border: 7px solid #66FCF1;
    }

    .movie-text {
        width: 350px;
        color: white;
        font-family: 'Montserrat Alternates';
        font-size: 17px;
        margin: 0;
        padding-left: 10px;
        margin-top: 16px;
        margin-bottom: 16px;
        line-height: 25px;
        text-align: left;
    }

    .genre-heading {
        text-align: center;
        color: #66FCF1;
        font-family: 'Montserrat';
    }

    /* #appreciation-container {
        display: flex;
        flex-direction: column;
        margin-top: 80px;
        align-items: center;
        /* margin-bottom: 40px;
    } */

     #closing-paragraph {
        text-align: center;
        font-family: 'Montserrat';
        font-size: 18px;
        color: #66FCF1;
        margin-bottom: 30px;
        width: 44%;
        line-height: 25px;
    }

    #movie-images-release-date {
        color: #66FCF1;
    }

    #movie-recommendation-info-link {
        /* color: #66FCF1; */
        color: #48b0a8;
    }

    .critics-consensus-heading {
        margin-top: 40px;
        margin-bottom: 10px;
    }

    .critics-consensus-text {
        margin-top: 5px;
        font-style: italic;
    }

    #more-recommendations-button {
        background-color: #F3EFE0;
        color: #48b0a8;
        border-color: #48b0a8;
    }

    .additional-movies {
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 40px;
    }

    .movie-info-button-recommendations {
        font-size: 19px;
        font-weight: bold;
        background-color: #F3EFE0;
        border-width: 3px;
        border-style: solid;
        border-color: #48b0a8;
        border-radius: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
    }

    #featured-movie-recommendations {
        margin-bottom: 50px;
    }

    /* .movie-recommendation-container {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        padding-left: 70px;
        padding-right: 70px;
    }
     */



    /*------------PREVIOUS YEAR BUTTON ---------------------*/
    
    .movie-button-container {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .movie-button-container :hover {
        cursor: pointer;
    }

    .show-movies-button {
        font-size: 30px;
        font-family: 'Montserrat';
        color: #66FCF1;
        background-color: #1F2833;
        border-width: 3px;
        border-style: solid;
        border-color: black;
        border-radius: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px
    }

    /* ----------- MOVIE REVIEW SECTION -------------------- */


    #movie-reviews-container {
        padding-bottom: 40px;
        padding-top: 40px;
        background: #F3EFE0;
    }

    .movie-reviews-header-text {
        /* color: #CA4A23; */
        /* color: #ff9d17; */
        color: #48b0a8;
        font-family: 'Dancing Script';
        font-size: 54px;
        text-align: center;
        margin: 0;
    }

    .movie-reviews-movie-image {
        /* border: 7px solid #CA4A23; */
        /* border: 7px solid #ff9d17;; */
        border: 7px solid #48b0a8;
    }

    .movie-reviews-poster-container {
        margin-bottom: 43px;
        margin-left: 5px;
        margin-right: 5px;
        max-width: min-content;
    }

    .movie-reviews-image-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 35px;
        flex-wrap: wrap;
        padding-left: 30px;
        padding-right: 30px;
    }

    .movie-reviews-release-date {
        margin-bottom: 0;
        margin-top: 10px;
        font-family: "Montserrat";
        /* color: black; */
        /* color: #CA4A23; */
        color: #48b0a8;
    }

    .ratings-container {
        /* max-width: fit-content; */
        text-align: center;
        font-family: "Montserrat";
        font-size: 18px;
        color: #48b0a8;
        font-weight: bold;
        line-height: 25px;
    }

    .my-rating-text {
        font-size: 17px;
        font-weight: normal;
        color: black;
        font-family: "Montserrat Alternates";
        text-align: left;
    }

    .my-rating-icon {
        width: 80px;
        height: 80px;
    }

    .movie-reviews-genre-heading {
        color: #48b0a8;
    }


    #movie-review-info-link {
        /* color: #48b0a8; */
        color: #66FCF1;
    }

    .movie-info-button-reviews {
        font-size: 19px;
        background-color: #1F2833;
        color: #66FCF1;
        border-width: 3px;
        border-style: solid;
        border-color: black;
        border-radius: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
    }
    

    /* ----------- SPECIAL HALLOWEEN SECTION ------------ */

    #special-halloween-section {
        /* background: #F3EFE0; */
        background: #1F2833;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .special-halloween-header-text {
        /* color: #CA4A23; */
        color: #ff9d17;
        font-family: 'Dancing Script';
        font-size: 54px;
        text-align: center;
        margin: 0;
    }

    .special-halloween-movie-image {
        /* border: 7px solid #CA4A23; */
        border: 7px solid #ff9d17;;
    }

    .halloween-movie-poster-container {
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .special-halloween-movie-image-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 35px;
        flex-wrap: wrap;
        padding-left: 30px;
        padding-right: 30px;
    }

    .release-date {
        margin-bottom: 0;
        margin-top: 10px;
        font-family: "Montserrat";
        /* color: black; */
        /* color: #CA4A23; */
        color: #ff9d17;
    }

    .movie-info-link {
        font-family: "Montserrat";
    }

    .movie-info-paragraph-wrapper {
        text-align: center;
    }

    #halloween-movie-info-link {
        color: #ff9d17;
    }


    .halloween-movie-info-button {
        font-size: 19px;
        background-color: #1F2833;
        border-width: 3px;
        border-style: solid;
        border-color: #ff9d17;
        border-radius: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
    }

    /* ------------- CONTACT SECTION ------------ */

    #contact-section {
        /* background: #F3EFE0; */
        background: #1F2833;
        padding-bottom: 40px;
        padding-top: 40px;
    }

    #contact-section-content {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    #contact-section-header-text {
        /* color: #48b0a8; */
        color: #66FCF1;
        text-align: center;
    }

    #mail-icon {
        height: 200px;
        width: 200px;
    }

    .contact-section-icons:hover {
         cursor: pointer;
    }

    #venmo-icon {
        height: 180px;
        width: 200px;
        /* border: 3px solid black; */
    }

    /* --------- Moblile --------------- */

        @media screen {
            @media (max-width: 600px) {

                #first-section {
                    height: 800px;
                    background-image: url("../../src/assets/projector.jpg");
                    background-size: 100% 800px;
                    background-repeat: no-repeat;
                }

                #text-container {
                    position: static;
                    padding-left: 20px;
                    /* text-align: center; */
                }

                .movie-text {
                    width: auto;
                    padding-right: 30px;
                    padding-left: 30px;
                    font-size: 15px;
                    text-align: center;
                }

                .my-rating-text {
                    text-align: center;
                }

                .movie-images {
                  width: 250px;
                  height: 375px;
                }

                .section-header-text {
                    font-size: 45px;
                    padding-left: 25px;
                    padding-right: 25px;
                }

                .genre-heading {
                    font-size: 1.3em;
                }

                #closing-paragraph {
                    width: auto;
                    font-size: 15px;
                    padding-left: 25px;
                    padding-right: 25px;
                }

                .special-halloween-movie-image-container {
                    padding-left: 0px;
                    padding-right: 0px;
                }

                .special-halloween-header-text {
                    font-size: 45px;
                }

                .movie-image-container {
                    flex-direction: column;
                    text-align: center;
                }

                #second-section-paragraph {
                    text-align: center;
                }

                #about-me-container {
                    padding-left: 30px;
                    padding-right: 30px;
                    width: auto;
                }

                #text-container {
                    display: flex;
                    flex-direction: column;
                    padding-left: 0px;
                    align-items: center;
                    text-align: center;
                }

                #profile-image {
                    height: 185px;
                    width: 185px;
                }

                #second-section-paragraph {
                    font-size: 17px;
                    padding-left: 25px;
                    padding-right: 25px;
                }

                .genre-heading {
                    font-size: 1.2em;
                }

                #mail-icon {
                    height: 150px;
                    width: 150px;
                }

                #venmo-icon {
                    height: 150px;
                    width: 150px;
                }

                #appreciation-container {
                    margin-top: 35px;
                }

                .paragraph-text {
                    font-size: 15px;
                }

                .movie-reviews-header-text {
                    font-size: 45px;
                }

                .movie-reviews-image-container {
                    flex-direction: column;
                    text-align: center;
                    flex-wrap: nowrap;
                }

                .movie-reviews-poster-container {
                    max-width: none;
                }
                
                .movie-recommendation-container {
                    padding-left: 0px;
                    padding-right: 0px;
                }

                  #contact-section-content {
                    flex-direction: column;
                    align-items: center;
                }

                #venmo-icon {
                    margin-top: 25px;
                    width: 143px;
                }

                .show-movies-button {
                    font-size: 20px;
                }

                #second-movie-container {
                    margin-top: 30px;
                }
            }
        
        }

    /* -------- Tablet ---------------- */

       @media screen {
        @media (min-width: 768px) and (max-width: 1024px) {

             #about-me-container {
                 width: 540px;
             }

              #second-section-paragraph {
                 text-align: center;
             }

               #third-section-table {
                 width: 540px;
             }

              .row {
                 flex-direction: column;
                 align-items: center;
             }

             .job-description {
                 width: auto;
             }

             .table-header {
                 width: auto;
             }

             .list-element {
                 margin-bottom: 15px;
             }

            .movie-image-container {
              padding-right: 30px;
              padding-left: 30px;
            }

            .section-header-text {
                text-align: center;
            }

            #closing-paragraph {
                width: 338px;
                font-size: 20px;
            }

            .special-halloween-header-text {
               text-align: center;
            }
        }
    }


</style>